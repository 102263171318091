import { notification } from 'antd';
import upperFirst from 'lodash/upperFirst';
import lowerCase from 'lodash/lowerCase';
import startCase from 'lodash/startCase';
import { store } from 'reduxStore/configureStore';

export const shapeMessage = (actionType, description) => {
	const tempType = actionType.split('_');
	const type = tempType[tempType.length - 1] === 'SUCCESS' ? 'success' : 'error';
	const MESSAGE = Array.isArray(description) ? description[0]?.message : description?.message;
	return { type, message: startCase(lowerCase(actionType)), description: upperFirst(lowerCase(MESSAGE)) };
};

export const openNotification = (MESSAGE) => {
	try {
		const { type, message, description } = MESSAGE;

		const DESCRIPTION =
			description !== 'Invalid access token' ? description : 'Invalid access token, please login again';

		switch (type) {
			case 'success':
				notification['success']({
					message,
					description,
					duration: 5,
				});
				break;
			case 'error':
				notification['error']({
					message: message || 'Error',
					description: DESCRIPTION || 'Something went wrong Please try again',
					duration: 5,
				});
				break;
			default:
				break;
		}
		store.dispatch({ type: 'CLEAR_MESSAGE' });
	} catch (e) {
		return;
	}
};
