import { shapeMessage } from 'util/openNotification';
import {
	GET_DATA_TAG,
	CREATE_DATA_TAG,
	UPDATE_DATA_TAG,
	DELETE_DATA_TAG,
	SEARCH_DATA_TAG,
	TOGGLE_DATATAG,
} from '../actions/dataTagActions';
import { message } from 'antd';

const initialState = {
	loading: false,
	crudLoading: false,
	toggling: false,
	dataTags: null,
	message: null,

	searchResult: null,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case 'CLEAR_MESSAGE':
			return {
				...state,
				message: null,
			};
		// ----- Loading States -----
		case GET_DATA_TAG.REQUEST:
			return { ...state, loading: true, message: null };
		case CREATE_DATA_TAG.REQUEST:
		case UPDATE_DATA_TAG.REQUEST:
		case DELETE_DATA_TAG.REQUEST:
			return { ...state, crudLoading: true, message: null };
		case TOGGLE_DATATAG.REQUEST:
			return { ...state, toggling: true };

		// ----- Success States -----
		case GET_DATA_TAG.SUCCESS:
			return { ...state, loading: false, dataTags: action.payload.dataTags };
		case CREATE_DATA_TAG.SUCCESS:
		case UPDATE_DATA_TAG.SUCCESS:
		case DELETE_DATA_TAG.SUCCESS:
			return {
				...state,
				crudLoading: false,
				dataTags: action.payload.dataTags,
				message: shapeMessage(action.type),
			};
		case TOGGLE_DATATAG.SUCCESS:
			return { ...state, toggling: false, dataTags: action.payload.dataTags };

		// ----- Failure States -----
		case GET_DATA_TAG.FAILURE:
			return { ...state, loading: false, message: shapeMessage(action.type, action.payload.error) };
		case CREATE_DATA_TAG.FAILURE:
		case UPDATE_DATA_TAG.FAILURE:
		case DELETE_DATA_TAG.FAILURE:
			return { ...state, crudLoading: false, message: shapeMessage(action.type, action.payload.error) };
		case TOGGLE_DATATAG.FAILURE:
			return { ...state, toggling: false, message: shapeMessage(action.type, action.payload.error) };

		// ----- Search Functionality -----
		case SEARCH_DATA_TAG: {
			const query = action.searchValue.toLowerCase();
			const searchResults = query
				? state.dataTags.filter(
						(tag) => tag.name.toLowerCase().includes(query) || tag.datapath.toLowerCase().includes(query)
				  )
				: null;
			return { ...state, searchResults };
		}

		// ----- Message Handling -----
		case 'CLEAR_MESSAGE':
			return { ...state, message: null };

		default:
			return state;
	}
};
