import {
	LOAD_MEMBERSHIPS,
	CREATE_MEMBERSHIP,
	UPDATE_MEMBERSHIP,
	DELETE_MEMBERSHIP,
	LEAVE_PROJECT,
} from '../actions/membershipActions';
import { shapeMessage } from 'util/openNotification';

const initialState = {
	memberships: [],
	membership: null,
	loading: false,
	message: null,

	memberCUDLoading: false,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case 'CLEAR_MESSAGE':
			return {
				...state,
				message: null,
			};
		case LOAD_MEMBERSHIPS.REQUEST:
			return {
				...state,
				loading: true,
			};
		case LOAD_MEMBERSHIPS.SUCCESS:
			return {
				...state,
				memberships: action.payload.memberships,
				message: null,
				loading: false,
			};
		case LOAD_MEMBERSHIPS.FAILURE:
			return {
				...state,
				message: shapeMessage(action.type, action.payload.error),
				loading: false,
			};
		case CREATE_MEMBERSHIP.REQUEST:
			return {
				...state,
				message: null,
				memberCUDLoading: true,
			};
		case CREATE_MEMBERSHIP.SUCCESS:
			return {
				...state,
				memberships: action.payload.memberships,
				message: shapeMessage(action.type),
				memberCUDLoading: false,
			};
		case CREATE_MEMBERSHIP.FAILURE:
			return {
				...state,
				message: shapeMessage(action.type, action.payload.error),
				memberCUDLoading: false,
			};
		case UPDATE_MEMBERSHIP.REQUEST:
			return {
				...state,
				message: null,
				memberCUDLoading: true,
			};
		case UPDATE_MEMBERSHIP.SUCCESS:
			return {
				...state,
				memberships: action.payload.memberships,
				message: shapeMessage(action.type),
				memberCUDLoading: false,
			};
		case UPDATE_MEMBERSHIP.FAILURE:
			return {
				...state,
				message: shapeMessage(action.type, action.payload.error),
				memberCUDLoading: false,
			};
		case DELETE_MEMBERSHIP.REQUEST:
			return {
				...state,
				message: null,
				memberCUDLoading: true,
			};
		case DELETE_MEMBERSHIP.SUCCESS:
			const newMemberships = state.memberships.filter(
				(membership) => membership.username !== action.payload.membership.username
			);
			return {
				...state,
				memberships: newMemberships,
				membership: null,
				message: shapeMessage(action.type),
				memberCUDLoading: false,
			};
		case DELETE_MEMBERSHIP.FAILURE:
			return {
				...state,
				message: shapeMessage(action.type, action.payload.error),
				memberCUDLoading: false,
			};
		case LEAVE_PROJECT.REQUEST:
			return {
				...state,
				message: null,
				loading: true,
			};
		case LEAVE_PROJECT.SUCCESS:
			return {
				...state,
				membership: action.payload.membership,
				message: shapeMessage(action.type),
				loading: false,
			};
		case LEAVE_PROJECT.FAILURE:
			return {
				...state,
				message: shapeMessage(action.type, action.payload.error),
				loading: false,
			};
		default:
			return state;
	}
};
