import gql from 'graphql-tag';

// fragment
export const DASH_FRAGMENT = gql`
	fragment dash on Dash {
		dashid
		projectid
		dashname
		dashsecret
		dashdescription
		maxsessionallow
		createdtime
	}
`;
export const DASHTOKEN_FRAGMENT = gql`
	fragment token on DashToken {
		dashtokenname
		dashid
		dashtoken
		level
		enabled
		createdtime
		firstconnecttime
	}
`;
export const DASHRESOURCE_FRAGMENT = gql`
	fragment resource on DashResource {
		dashid
		type
		alias
		objectid
		msg_sub
		msg_pub
		shadow_read
		shadow_write
		feed_read
		feed_write
		push_sub
		createdtime
		modifiedtime
	}
`;
const DEVICE_NAME_FRAGMENT = gql`
	fragment deviceName on Device {
		alias
		deviceid
	}
`;

// ----- query -----
const QUERY_DASH = gql`
	query ($dashid: String!) {
		dash(dashid: $dashid) {
			...dash
		}
	}
	${DASH_FRAGMENT}
`;
const QUERY_DASH_LIST = gql`
	query ($projectid: String!) {
		dashList(filter: { projectid: $projectid }) {
			...dash
		}
	}
	${DASH_FRAGMENT}
`;
const QUERY_DASH_TOKEN = gql`
	query ($dashid: String!) {
		dashToken(dashid: $dashid) {
			...token
		}
	}
	${DASHTOKEN_FRAGMENT}
`;
const QUERY_DASH_RESOURCE = gql`
	query ($dashid: String) {
		dashResource(dashid: $dashid) {
			...resource
		}
	}
	${DASHRESOURCE_FRAGMENT}
`;
const QUERY_DEVICE_NAME = gql`
	query ($filter: DeviceFilterKey) {
		deviceList(filter: $filter) {
			...deviceName
		}
	}
	${DEVICE_NAME_FRAGMENT}
`;

// ----- mutation -----
const CREATE_DASH = gql`
	mutation ($projectid: String!, $name: String!, $description: String) {
		createDash(projectid: $projectid, dashname: $name, dashdescription: $description) {
			...dash
		}
	}
	${DASH_FRAGMENT}
`;
const UPDATE_DASH = gql`
	mutation ($dashid: String!, $dashname: String, $dashdescription: String) {
		updateDash(dashid: $dashid, dashname: $dashname, dashdescription: $dashdescription) {
			...dash
		}
	}
	${DASH_FRAGMENT}
`;
const DELETE_DASH = gql`
	mutation ($dashid: String!) {
		deleteDash(dashid: $dashid) {
			...dash
		}
	}
	${DASH_FRAGMENT}
`;
const CREATE_DASHTOKEN = gql`
	mutation ($dashid: String, $level: Int) {
		createDashToken(dashid: $dashid, level: $level) {
			...token
		}
	}
	${DASHTOKEN_FRAGMENT}
`;
// dash resource
const CREATE_DASH_RESOURCE = gql`
	mutation (
		$dashid: String!
		$type: String
		$alias: String
		$objectid: String!
		$msg_pub: Boolean
		$msg_sub: Boolean
		$shadow_read: Boolean
		$shadow_write: Boolean
		$feed_read: Boolean
		$feed_write: Boolean
		$push_sub: Boolean
	) {
		createDashResource(
			dashid: $dashid
			type: $type
			alias: $alias
			objectid: $objectid
			msg_pub: $msg_pub
			msg_sub: $msg_sub
			shadow_read: $shadow_read
			shadow_write: $shadow_write
			feed_read: $feed_read
			feed_write: $feed_write
			push_sub: $push_sub
		) {
			...resource
		}
	}
	${DASHRESOURCE_FRAGMENT}
`;
const UPDATE_DASH_RESOURCE = gql`
	mutation (
		$dashid: String!
		$alias: String
		$objectid: String!
		$msg_pub: Boolean
		$msg_sub: Boolean
		$shadow_read: Boolean
		$shadow_write: Boolean
		$feed_read: Boolean
		$feed_write: Boolean
		$push_sub: Boolean
	) {
		updateDashResource(
			dashid: $dashid
			alias: $alias
			objectid: $objectid
			msg_pub: $msg_pub
			msg_sub: $msg_sub
			shadow_read: $shadow_read
			shadow_write: $shadow_write
			feed_read: $feed_read
			feed_write: $feed_write
			push_sub: $push_sub
		) {
			...resource
		}
	}
	${DASHRESOURCE_FRAGMENT}
`;
const DELETE_DASH_RESOURCE = gql`
	mutation ($dashid: String!, $objectid: String) {
		deleteDashResource(dashid: $dashid, objectid: $objectid) {
			...resource
		}
	}
	${DASHRESOURCE_FRAGMENT}
`;
const DELETE_ALL_DASH_RESOURCE = gql`
	mutation ($dashid: String!) {
		deleteAllDashResources(dashid: $dashid) {
			...resource
		}
	}
	${DASHRESOURCE_FRAGMENT}
`;

export {
	QUERY_DASH,
	QUERY_DASH_LIST,
	QUERY_DASH_TOKEN,
	QUERY_DASH_RESOURCE,
	QUERY_DEVICE_NAME,
	CREATE_DASH,
	UPDATE_DASH,
	DELETE_DASH,
	CREATE_DASHTOKEN,
	CREATE_DASH_RESOURCE,
	UPDATE_DASH_RESOURCE,
	DELETE_DASH_RESOURCE,
	DELETE_ALL_DASH_RESOURCE,
};
