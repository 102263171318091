import { all, put, call, takeLatest, select, takeEvery } from 'redux-saga/effects';
import * as actions from 'reduxStore/actions/freeboardNewActions';
import { apolloQuery, apolloMutation } from 'util/apollo';
import {
	CREATE_DASH,
	CREATE_DASHTOKEN,
	CREATE_DASH_RESOURCE,
	DELETE_DASH,
	DELETE_DASH_RESOURCE,
	DELETE_ALL_DASH_RESOURCE,
	QUERY_DASH,
	QUERY_DASH_LIST,
	QUERY_DASH_RESOURCE,
	QUERY_DASH_TOKEN,
	UPDATE_DASH_RESOURCE,
	QUERY_DEVICE_NAME,
	UPDATE_DASH,
} from 'util/apollo/nexpieGraphQL/freeboardNew';

function* loadDash(action) {
	const { dashid } = action.payload;
	try {
		const { data, errors } = yield call(async () => await apolloQuery(QUERY_DASH, { dashid }));
		if (errors) throw errors;

		if (data) yield put(actions.loadDash.success(data.dash));
	} catch (error) {
		yield put(actions.loadDash.failure(error));
	}
}
function* loadDashList(action) {
	const { projectid } = action.payload;
	try {
		const { data, errors } = yield call(async () => await apolloQuery(QUERY_DASH_LIST, { projectid }));
		if (errors) throw errors;

		if (data) yield put(actions.loadDashList.success(data.dashList));
	} catch (error) {
		yield put(actions.loadDashList.failure(error));
	}
}

function* createDash(action) {
	const { projectid, dashinfo } = action.payload;
	try {
		const { data, errors } = yield call(
			async () =>
				await apolloMutation(CREATE_DASH, {
					projectid,
					name: dashinfo.name,
					description: dashinfo.description,
				})
		);
		if (errors) throw errors;

		const dashList = yield select(({ freeboardNewReducer }) => freeboardNewReducer.dashList);
		const NEW_LIST = [...dashList, data.createDash];
		yield put(actions.createDash.success(NEW_LIST));
	} catch (error) {
		yield put(actions.createDash.failure(error));
	}
}
function* updateDash(action) {
	const { dashinfo } = action.payload;
	try {
		const { data, errors } = yield call(
			async () =>
				await apolloMutation(UPDATE_DASH, {
					...dashinfo,
				})
		);
		if (errors) throw errors;

		const dashList = yield select(({ freeboardNewReducer }) => freeboardNewReducer.dashList);
		const NEW_LIST = dashList.map((dash) => (dash.dashid === dashinfo.dashid ? { ...dash, ...data.updateDash } : dash));
		yield put(actions.updateDash.success(NEW_LIST));
	} catch (error) {
		yield put(actions.updateDash.failure(error));
	}
}
function* deleteDash(action) {
	const { dashid } = action.payload;
	try {
		const { errors } = yield call(async () => await apolloMutation(DELETE_DASH, { dashid }));
		if (errors) throw errors;

		const dashList = yield select(({ freeboardNewReducer }) => freeboardNewReducer.dashList);
		const NEW_LIST = dashList.filter((dash) => dash.dashid !== dashid);
		yield put(actions.deleteDash.success(NEW_LIST));
	} catch (error) {
		yield put(actions.deleteDash.failure(error));
	}
}

// dash token
function* loadDashTokenList(action) {
	const { dashid } = action.payload;
	try {
		const { data, errors } = yield call(async () => await apolloQuery(QUERY_DASH_TOKEN, { dashid }));
		if (errors) throw errors;

		yield put(actions.loadDashToken.success(data.dashToken));
	} catch (error) {
		yield put(actions.loadDashToken.failure(error));
	}
}
function* createDashToken(action) {
	const { dashid, level } = action.payload;
	try {
		const { data, errors } = yield call(async () => await apolloMutation(CREATE_DASHTOKEN, { dashid, level }));
		if (errors) throw errors;

		const dashTokenList = yield select(({ freeboardNewReducer }) => freeboardNewReducer.dashTokenList);
		const NEW_LIST = [...dashTokenList, data.createDashToken];
		yield put(actions.createDashToken.success(NEW_LIST));
	} catch (error) {
		yield put(actions.createDashToken.failure(error));
	}
}

// dash resource
function* loadDashResource(action) {
	const { projectid, dashid } = action.payload;
	try {
		const { data, errors } = yield call(async () => await apolloQuery(QUERY_DASH_RESOURCE, { dashid }));
		if (errors) throw errors;

		const deviceRes = yield call(async () => await apolloQuery(QUERY_DEVICE_NAME, { filter: { projectid } }));

		yield put(actions.loadDashResource.success(deviceRes.data.deviceList, data.dashResource));
	} catch (error) {
		yield put(actions.loadDashResource.failure(error));
	}
}
function* createDashResource(action) {
	const { dashid, objectid, alias, privilages } = action.payload;
	const isAllow = (type) => {
		const allow = privilages.find((pvl) => pvl === type);

		return allow ? true : false;
	};

	try {
		const { data, errors } = yield call(
			async () =>
				await apolloMutation(CREATE_DASH_RESOURCE, {
					dashid,
					objectid,
					type: 'DEVICE',
					alias,
					msg_sub: isAllow('msg_sub'),
					msg_pub: isAllow('msg_pub'),
					shadow_read: isAllow('shadow_read'),
					shadow_write: isAllow('shadow_write'),
					feed_read: isAllow('feed_read'),
					feed_write: isAllow('feed_write'),
					push_sub: isAllow('push_sub'),
				})
		);
		if (errors) throw errors;

		const prevDashResource = yield select(({ freeboardNewReducer }) => freeboardNewReducer.dashResource);
		let NEW_LIST = [];
		// check if objectid already create
		if (prevDashResource.find((rs) => rs.objectid === objectid)) {
			// if true update existing resource
			NEW_LIST = prevDashResource.map((res) => (res.objectid === objectid ? data.createDashResource : res));
		} else {
			// else add new one
			NEW_LIST = [...prevDashResource, data.createDashResource];
		}

		yield put(actions.createDashResource.success(NEW_LIST));
	} catch (error) {
		yield put(actions.createDashResource.failure(error));
	}
}
function* updateDashResource(action) {
	const { dashid, objectid, alias, privilages } = action.payload;
	const isAllow = (type) => {
		const allow = privilages.find((pvl) => pvl === type);

		return allow ? true : false;
	};
	try {
		const { data, errors } = yield call(
			async () =>
				await apolloMutation(UPDATE_DASH_RESOURCE, {
					dashid,
					objectid,
					type: 'DEVICE',
					alias,
					msg_sub: isAllow('msg_sub'),
					msg_pub: isAllow('msg_pub'),
					shadow_read: isAllow('shadow_read'),
					shadow_write: isAllow('shadow_write'),
					feed_read: isAllow('feed_read'),
					feed_write: isAllow('feed_write'),
					push_sub: isAllow('push_sub'),
				})
		);
		if (errors) throw errors;

		const prevDashResource = yield select(({ freeboardNewReducer }) => freeboardNewReducer.dashResource);
		const NEW_LIST = prevDashResource.map((res) => (res.objectid === objectid ? data.updateDashResource : res));
		yield put(actions.updateDashResource.success(NEW_LIST));
	} catch (error) {
		yield put(actions.updateDashResource.failure(error));
	}
}
function* deleteDashResource(action) {
	const { dashid, objectid } = action.payload;
	try {
		const { errors } = yield call(async () => await apolloMutation(DELETE_DASH_RESOURCE, { dashid, objectid }));
		if (errors) throw errors;

		const prevDashResource = yield select(({ freeboardNewReducer }) => freeboardNewReducer.dashResource);
		const NEW_LIST = prevDashResource.filter((res) => res.objectid !== objectid);
		yield put(actions.deleteDashResource.success(NEW_LIST));
	} catch (error) {
		yield put(actions.deleteDashResource.failure(error));
	}
}
function* deleteAllDashResource(action) {
	const { dashid } = action.payload;
	try {
		const { errors } = yield put(async () => await apolloMutation(DELETE_ALL_DASH_RESOURCE, { dashid }));
		if (errors) throw errors;

		yield put(actions.deleteAllDashResource.success([]));
	} catch (error) {
		yield put(actions.deleteAllDashResource.failure(error));
	}
}

export default function* watchFreeboardNew() {
	yield all([
		takeLatest(actions.LOAD_DASH.REQUEST, loadDash),
		takeLatest(actions.LOAD_DASH_LIST.REQUEST, loadDashList),
		takeLatest(actions.LOAD_DASH_TOKEN.REQUEST, loadDashTokenList),
		takeLatest(actions.LOAD_DASH_RESOURCE.REQUEST, loadDashResource),
		takeEvery(actions.CREATE_DASH.REQUEST, createDash),
		takeEvery(actions.UPDATE_DASH.REQUEST, updateDash),
		takeEvery(actions.DELETE_DASH.REQUEST, deleteDash),
		takeEvery(actions.CREATE_DASH_TOKEN.REQUEST, createDashToken),
		takeEvery(actions.CREATE_DASH_RESOURCE.REQUEST, createDashResource),
		takeEvery(actions.UPDATE_DASH_RESOURCE.REQUEST, updateDashResource),
		takeEvery(actions.DELETE_DASH_RESOURCE.REQUEST, deleteDashResource),
		takeEvery(actions.DELETE_ALL_DASH_RESOURCE.REQUEST, deleteAllDashResource),
	]);
}
