import gql from 'graphql-tag';

const PROJECT_FRAGMENT = gql`
	fragment project on Project {
		projectname
		projectid
		createdtime
		description
		billingaccountid
		tags
		tag
		hashtag
		numberdevice
		numbergroup
		numberdeviceonline
		numberdeviceoffline
		quota {
			apicall
			connection
			message
			shadowops
			store
			trigger
			datasource
		}
		userlevel
	}
`;

const QUERY_PROJECT_LIST = gql`
	query _projectList {
		projectList(limit: 100, offset: 0) {
			...project
		}
	}
	${PROJECT_FRAGMENT}
`;

const QUERY_PROJECT = gql`
	query _project($projectid: String!) {
		project(projectid: $projectid) {
			...project
		}
	}
	${PROJECT_FRAGMENT}
`;

const CREATE_PROJECT = gql`
	mutation _createProject(
		$projectname: String!
		$billingaccountid: String
		$description: String
		$tag: String
		$hashtag: [String]
		$tags: JSON
	) {
		createProject(
			projectname: $projectname
			billingaccountid: $billingaccountid
			description: $description
			tag: $tag
			hashtag: $hashtag
			tags: $tags
		) {
			...project
		}
	}
	${PROJECT_FRAGMENT}
`;

const UPDATE_PROJECT = gql`
	mutation _updateProject(
		$projectid: String!
		$projectname: String
		$description: String
		$tag: String
		$hashtag: [String]
		$tags: JSON
	) {
		updateProject(
			projectid: $projectid
			projectname: $projectname
			description: $description
			tag: $tag
			hashtag: $hashtag
			tags: $tags
		) {
			...project
		}
	}
	${PROJECT_FRAGMENT}
`;

const DELETE_PROJECT = gql`
	mutation _deleteProject($projectid: String!) {
		deleteProject(projectid: $projectid) {
			...project
		}
	}
	${PROJECT_FRAGMENT}
`;

const QUERY_PROJECTS_BY_BILLING = gql`
	query {
		BillingAccountProjectInfo(billingaccountid: $billingaccountid) {
			projectid
		}
	}
`;

export {
	PROJECT_FRAGMENT,
	QUERY_PROJECT_LIST,
	QUERY_PROJECT,
	CREATE_PROJECT,
	UPDATE_PROJECT,
	DELETE_PROJECT,
	QUERY_PROJECTS_BY_BILLING,
};
