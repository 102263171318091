export const SET_SEARCH_RESULT = 'SET_SEARCH_RESULT';
export const RESET_SEARCH = 'RESET_SEARCH';
export const SET_FILTER_RESULT = 'SET_FILTER_RESULT';
export const RESET_FILTER = 'RESET_FILTER';

export const SET_SEARCH_OR_FILTER_RESULT = 'SET_SEARCH_OR_FILTER_RESULT';
export const RESET_SEARCH_OR_FILTER = 'RESET_SEARCH_OR_FILTER';
export const SET_BILLING = 'SET_BILLING';

export const setSearchResult = (searchValue, isTagGotSearch, searchResult) => ({
	type: SET_SEARCH_RESULT,
	searchValue,
	isTagGotSearch,
	searchResult,
});

export const resetSearch = () => ({
	type: RESET_SEARCH,
});

export const setFilterResult = (filterResult) => ({
	type: SET_FILTER_RESULT,
	filterResult,
});

export const resetFilter = () => ({
	type: RESET_FILTER,
});

export const setSearchOrFilterResult = (searchOrFilterResult) => ({
	type: SET_SEARCH_OR_FILTER_RESULT,
	searchOrFilterResult,
});

export const resetSearchAndFilter = () => ({
	type: RESET_SEARCH_OR_FILTER,
});

export const setBilling = (selectedBilling) => ({
	type: SET_SEARCH_OR_FILTER_RESULT,
	selectedBilling,
});
