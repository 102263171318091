import gql from 'graphql-tag';

const DASHBOARD_FRAGMENT = gql`
	fragment dashboard on Dashboard {
		dashboardid
		dashboardgroupid
		name
		type
		shared
		description
		createdtime
	}
`;

const SHARE_FRAGMENT = gql`
	fragment share on DashSharing {
		dashid
		dashtoken
		sharecode
		projectid
		level
		enabled
		hashtag
		valid_from
		valid_to
		hashcode
		shareurl
		createdtime
		enable_push
	}
`;
const DASH_USER_FRAGMENT = gql`
	fragment dashUser on DashUser {
		dashid
		userid
		username
		level
		enable_push
	}
`;

const QUERY_DASHBOARD_LIST = gql`
	query _dashboardList($projectid: String) {
		dashboardList(filter: { projectid: $projectid }, limit: "100") {
			...dashboard
		}
	}
	${DASHBOARD_FRAGMENT}
`;

const QUERY_DASHBOARD = gql`
	query _dashboard($dashboardid: String!) {
		dashboard(dashboardid: $dashboardid) {
			...dashboard
		}
	}
	${DASHBOARD_FRAGMENT}
`;

const CREATE_DASHBOARD = gql`
	mutation _createDashboard(
		$projectid: String!
		$name: String!
		$type: String
		$shared: Boolean
		$description: String
		$dashboardgroupid: String
	) {
		createDashboard(
			projectid: $projectid
			name: $name
			type: $type
			shared: $shared
			description: $description
			dashboardgroupid: $dashboardgroupid
		) {
			...dashboard
		}
	}
	${DASHBOARD_FRAGMENT}
`;

const UPDATE_DASHBOARD = gql`
	mutation _updateDashboard(
		$dashboardid: String!
		$name: String!
		$type: String
		$shared: Boolean
		$description: String
	) {
		updateDashboard(dashboardid: $dashboardid, name: $name, type: $type, shared: $shared, description: $description) {
			...dashboard
		}
	}
	${DASHBOARD_FRAGMENT}
`;

const DELETE_DASHBOARD = gql`
	mutation _deleteDashboard($dashboardid: String!) {
		deleteDashboard(dashboardid: $dashboardid) {
			...dashboard
		}
	}
	${DASHBOARD_FRAGMENT}
`;

// ----- SHARED -----
const QUERY_PUBLIC_SHARED = gql`
	query GetPublicShared($dashid: String!) {
		simpleSharingList(dashid: $dashid) {
			...share
		}
	}
	${SHARE_FRAGMENT}
`;
const CREATE_PUBLIC_SHARED = gql`
	mutation CreatePublicShared(
		$dashid: String
		$enabled: Boolean
		$level: Int
		$valid_from: Timestamp
		$valid_to: Timestamp
		$enable_push: Boolean
	) {
		createSimpleSharing(
			dashid: $dashid
			enabled: $enabled
			level: $level
			valid_from: $valid_from
			valid_to: $valid_to
			enable_push: $enable_push
		) {
			...share
		}
	}
	${SHARE_FRAGMENT}
`;
const UPDATE_PUBLIC_SHARED = gql`
	mutation UpdatePublicShared(
		$dashid: String
		$enabled: Boolean
		$level: Int
		$valid_from: Timestamp
		$valid_to: Timestamp
		$enable_push: Boolean
	) {
		updateSimpleSharing(
			dashid: $dashid
			enabled: $enabled
			level: $level
			valid_from: $valid_from
			valid_to: $valid_to
			enable_push: $enable_push
		) {
			...share
		}
	}
	${SHARE_FRAGMENT}
`;
const DELETE_PUBLIC_SHARED = gql`
	mutation DeletePublicShared($dashid: String) {
		deleteSimpleSharing(dashid: $dashid) {
			...share
		}
	}
	${SHARE_FRAGMENT}
`;
const QUERY_PRIVATE_SHARED = gql`
	query GetPrivateShared($dashid: String) {
		dashUserShare(dashid: $dashid) {
			...dashUser
		}
	}
	${DASH_USER_FRAGMENT}
`;
const CREATE_USER_SHARE = gql`
	mutation CreateUserShare($dashid: String, $username: String, $level: Int, $enable_push: Boolean) {
		createDashUserShare(dashid: $dashid, username: $username, level: $level, enable_push: $enable_push) {
			...dashUser
		}
	}
	${DASH_USER_FRAGMENT}
`;
const UPDATE_USER_SHARE = gql`
	mutation UpdateUserShare($dashid: String, $username: String, $level: Int, $enable_push: Boolean) {
		updateDashUserShare(dashid: $dashid, username: $username, level: $level, enable_push: $enable_push) {
			...dashUser
		}
	}
	${DASH_USER_FRAGMENT}
`;
const DELETE_USER_SHARE = gql`
	mutation DeleteUserShare($dashid: String, $username: String) {
		deleteDashUserShare(dashid: $dashid, username: $username) {
			...dashUser
		}
	}
	${DASH_USER_FRAGMENT}
`;

export {
	QUERY_DASHBOARD_LIST,
	QUERY_DASHBOARD,
	CREATE_DASHBOARD,
	UPDATE_DASHBOARD,
	DELETE_DASHBOARD,
	QUERY_PUBLIC_SHARED,
	CREATE_PUBLIC_SHARED,
	UPDATE_PUBLIC_SHARED,
	DELETE_PUBLIC_SHARED,
	QUERY_PRIVATE_SHARED,
	CREATE_USER_SHARE,
	UPDATE_USER_SHARE,
	DELETE_USER_SHARE,
};
