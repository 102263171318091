import {
	SET_SEARCH_RESULT,
	RESET_SEARCH,
	SET_FILTER_RESULT,
	RESET_FILTER,
	SET_SEARCH_OR_FILTER_RESULT,
	RESET_SEARCH_OR_FILTER,
	SET_BILLING,
} from '../actions/utilitiesActions';

const initState = {
	search: {
		isSearch: false,
		searchValue: '',
		isTagGotSearch: false,
		searchResult: [],
	},
	filter: {
		isFilter: false,
		filterResult: [],
	},
	searchOrFilter: {
		isSearchOrFilter: false,
		searchOrFilterResult: [],
	},
};

const utilitiesReducer = (state = initState, action = {}) => {
	switch (action.type) {
		case SET_SEARCH_RESULT:
			return {
				...state,
				search: {
					isSearch: true,
					searchValue: action.searchValue,
					isTagGotSearch: action.isTagGotSearch,
					searchResult: action.searchResult,
				},
			};

		case RESET_SEARCH:
			return { ...state, search: { isSearch: false, searchValue: '', isTagGotSearch: false, searchResult: [] } };

		case SET_FILTER_RESULT:
			return { ...state, filter: { isFilter: true, filterResult: action.filterResult } };

		case RESET_FILTER:
			return { ...state, filter: { isFilter: false, filterResult: [] } };

		case SET_SEARCH_OR_FILTER_RESULT:
			return {
				...state,
				searchOrFilter: { isSearchOrFilter: true, searchOrFilterResult: action.searchOrFilterResult },
			};

		case SET_BILLING:
			return {
				...state,
				selectedBilling: action.selectedBilling,
			};
		case RESET_SEARCH_OR_FILTER:
			return initState;

		default:
			return state;
	}
};

export default utilitiesReducer;
