import { message } from 'antd';
import {
	LOAD_DEVICE_GROUPS,
	LOAD_DEVICE_GROUP,
	CREATE_DEVICE_GROUP,
	UPDATE_DEVICE_GROUP,
	DELETE_DEVICE_GROUP,
} from '../actions/deviceGroupActions';
import { shapeMessage } from 'util/openNotification';

const initialState = {
	deviceGroups: [],
	devicesInGroup: [],
	deviceGroup: null,
	groupsLoading: false,
	groupsMessage: null,
	loading: false,
	message: null,
	gCUDLoading: false,
	moveMessage: null,
	moveLoading: false,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case 'CLEAR_MESSAGE':
			return {
				...state,
				groupsMessage: null,
				message: null,
				moveMessage: null,
			};
		case LOAD_DEVICE_GROUPS.REQUEST:
			return {
				...state,
				groupsLoading: true,
			};
		case LOAD_DEVICE_GROUPS.SUCCESS:
			return {
				...state,
				deviceGroups: action.payload.deviceGroups,
				groupsMessage: null,
				groupsLoading: false,
			};
		case LOAD_DEVICE_GROUPS.FAILURE:
			return {
				...state,
				groupsMessage: shapeMessage(action.type, action.payload.error),
				groupsLoading: false,
			};
		case LOAD_DEVICE_GROUP.REQUEST:
			return {
				...state,
				loading: true,
			};
		case LOAD_DEVICE_GROUP.SUCCESS:
			return {
				...state,
				devicesInGroup: action.payload.devices,
				message: null,
				loading: false,
			};
		case LOAD_DEVICE_GROUP.FAILURE:
			return {
				...state,
				message: shapeMessage(action.type, action.payload.error),
				loading: false,
			};

		case CREATE_DEVICE_GROUP.REQUEST:
			return {
				...state,
				gCUDLoading: true,
			};
		case CREATE_DEVICE_GROUP.SUCCESS:
			return {
				...state,
				deviceGroups: action.payload.deviceGroups,
				deviceGroup: action.payload.deviceGroup,
				message: shapeMessage(action.type),
				gCUDLoading: false,
			};
		case CREATE_DEVICE_GROUP.FAILURE:
			return {
				...state,
				message: shapeMessage(action.type, action.payload.error),
				gCUDLoading: false,
			};

		case UPDATE_DEVICE_GROUP.REQUEST:
			return {
				...state,
				gCUDLoading: true,
			};
		case UPDATE_DEVICE_GROUP.SUCCESS:
			return {
				...state,
				message: shapeMessage(action.type),
				deviceGroups: action.payload.deviceGroups,
				deviceGroup: action.payload.deviceGroup,
				gCUDLoading: false,
			};
		case UPDATE_DEVICE_GROUP.FAILURE:
			return {
				...state,
				message: shapeMessage(action.type, action.payload.error),
				gCUDLoading: false,
			};

		case DELETE_DEVICE_GROUP.REQUEST:
			return {
				...state,
				gCUDLoading: true,
			};
		case DELETE_DEVICE_GROUP.SUCCESS:
			return {
				...state,
				deviceGroups: action.payload.deviceGroups,
				deviceGroup: action.payload.deviceGroup,
				message: shapeMessage(action.type),
				gCUDLoading: false,
			};
		case DELETE_DEVICE_GROUP.FAILURE:
			return {
				...state,
				message: shapeMessage(action.type, action.payload.error),
				gCUDLoading: false,
			};

		default:
			return state;
	}
};
