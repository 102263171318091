import {
	CREATE_FREEBOARD,
	DELETE_FREEBOARD,
	LOAD_FREEBOARD,
	LOAD_FREEBOARDS,
	UPDATE_FREEBOARD,
} from 'reduxStore/actions/freeboardActions';
import { shapeMessage } from 'util/openNotification';

const initialState = {
	freeboards: [],
	freeboard: null,

	loading: false,
	crudLoading: false,

	message: '',
	crudMessage: '',
};

export default (state = initialState, action) => {
	switch (action.type) {
		case 'CLEAR_MESSAGE':
			return {
				...state,
				message: null,
				crudMessage: null,
			};
		case LOAD_FREEBOARDS.REQUEST:
		case LOAD_FREEBOARD.REQUEST:
			return {
				...state,
				loading: true,
			};
		case LOAD_FREEBOARDS.SUCCESS:
			return {
				...state,
				loading: false,
				freeboards: action.payload.freeboards,
			};
		case LOAD_FREEBOARD.SUCCESS:
			return {
				...state,
				loading: false,
				freeboard: action.payload.freeboard,
			};
		case LOAD_FREEBOARDS.FAILURE:
		case LOAD_FREEBOARD.FAILURE:
			return {
				...state,
				loading: false,
				message: shapeMessage(action.type, action.payload.error),
			};

		// CRUD
		case CREATE_FREEBOARD.REQUEST:
		case UPDATE_FREEBOARD.REQUEST:
		case DELETE_FREEBOARD.REQUEST:
			return {
				...state,
				crudLoading: true,
			};
		case CREATE_FREEBOARD.SUCCESS:
		case UPDATE_FREEBOARD.SUCCESS:
		case DELETE_FREEBOARD.SUCCESS:
			return {
				...state,
				crudLoading: false,
				freeboards: action.payload.freeboards,
				crudMessage: shapeMessage(action.type),
			};
		case CREATE_FREEBOARD.FAILURE:
		case UPDATE_FREEBOARD.FAILURE:
		case DELETE_FREEBOARD.FAILURE:
			return {
				crudLoading: false,
				crudMessage: shapeMessage(action.type, action.payload.error),
			};

		default:
			return state;
	}
};
