import gql from 'graphql-tag';

const TRIGGER_FRAGMENT = gql`
	fragment trigger on Trigger {
		triggerid
		triggername
		type
		event
		condition
		action
		context {
			key
			value
		}
		enabled
	}
`;

const QUERY_TRIGGER = gql`
	query _trigger($deviceid: String!, $triggerid: String, $type: String) {
		trigger(deviceid: $deviceid, triggerid: $triggerid, type: $type) {
			...trigger
		}
	}
	${TRIGGER_FRAGMENT}
`;

// CRUD
const CREATE_TRIGGER = gql`
	mutation _createTrigger(
		$deviceid: String!
		$triggerid: String
		$triggername: String
		$type: TriggerType!
		$event: TriggerEvent!
		$condition: String
		$action: [String]
		$context: [ActionVarInput]
		$enabled: Boolean
	) {
		createTrigger(
			deviceid: $deviceid
			triggerid: $triggerid
			triggername: $triggername
			type: $type
			event: $event
			condition: $condition
			action: $action
			context: $context
			enabled: $enabled
		) {
			...trigger
		}
	}
	${TRIGGER_FRAGMENT}
`;

const UPDATE_TRIGGER = gql`
	mutation _updateTrigger(
		$deviceid: String!
		$triggerid: String!
		$triggername: String
		$type: TriggerType
		$event: TriggerEvent
		$condition: String
		$action: [String]
		$context: [ActionVarInput]
		$enabled: Boolean
	) {
		updateTrigger(
			deviceid: $deviceid
			triggerid: $triggerid
			triggername: $triggername
			type: $type
			event: $event
			condition: $condition
			action: $action
			context: $context
			enabled: $enabled
		) {
			...trigger
		}
	}
	${TRIGGER_FRAGMENT}
`;
const DELETE_TRIGGER = gql`
	mutation _deleteTrigger($deviceid: String!, $triggerid: String!) {
		deleteTrigger(deviceid: $deviceid, triggerid: $triggerid) {
			...trigger
		}
	}
	${TRIGGER_FRAGMENT}
`;
const DELETE_ALL_TRIGGER = gql`
	mutation _deleteAllTrigger($deviceid: String!) {
		deleteAllTriggers(deviceid: $deviceid) {
			...trigger
		}
	}
	${TRIGGER_FRAGMENT}
`;

// TOGGLE TRIGGER
const ENABLE_ALL_TRIGGERS = gql`
	mutation _enableAllTriggers($deviceid: String!) {
		enableAllTriggers(deviceid: $deviceid) {
			...trigger
		}
	}
	${TRIGGER_FRAGMENT}
`;
const DISABLE_ALL_TRIGGERS = gql`
	mutation _disableAllTriggers($deviceid: String!) {
		disableAllTriggers(deviceid: $deviceid) {
			...trigger
		}
	}
	${TRIGGER_FRAGMENT}
`;
const ENABLE_TRIGGER = gql`
	mutation _enableTrigger($deviceid: String!, $triggerid: [String]!) {
		enableTrigger(deviceid: $deviceid, triggerid: $triggerid) {
			...trigger
		}
	}
	${TRIGGER_FRAGMENT}
`;
const DISABLE_TRIGGER = gql`
	mutation _disableTrigger($deviceid: String!, $triggerid: [String]!) {
		disableTrigger(deviceid: $deviceid, triggerid: $triggerid) {
			...trigger
		}
	}
	${TRIGGER_FRAGMENT}
`;

export {
	QUERY_TRIGGER,
	ENABLE_ALL_TRIGGERS,
	DISABLE_ALL_TRIGGERS,
	ENABLE_TRIGGER,
	DISABLE_TRIGGER,
	CREATE_TRIGGER,
	UPDATE_TRIGGER,
	DELETE_TRIGGER,
	DELETE_ALL_TRIGGER,
};
