import gql from 'graphql-tag';
import { PROJECT_FRAGMENT } from './project';
import { FLOWAGENT_FRAGMENT } from './flowagent';
// import { DASHBOARD_FRAGMENT } from './dashboard';

const BILLING_ACCOUNT_FRAGMENT = gql`
	fragment billingAccount on BillingAccountProfile {
		billingaccountid
		name
	}
`;

const QUERY_BILLING_ACCOUNT = gql`
	query ($billingaccountid: String) {
		BillingAccountProfile(billingaccountid: $billingaccountid) {
			...billingAccount
		}
	}
	${BILLING_ACCOUNT_FRAGMENT}
`;

const QUERY_USAGE_PAGE = gql`
	query ($billingaccountid: String) {
		billingAccount(billingaccountid: $billingaccountid) {
			...billingAccount
		}
		projectList {
			...project
		}
		flowagentList {
			...flowagent
		}
	}
	${FLOWAGENT_FRAGMENT}
	${PROJECT_FRAGMENT}
	${BILLING_ACCOUNT_FRAGMENT}
`;

//-----NEW BILLING------//

const MY_BILLING_ACCOUNT_FRAGMENT = gql`
	fragment myBillingAccount on BillingMembershipProfile {
		billingaccountid
		name
		level
	}
`;

const BILLING_ACCOUNT_SUBSCRIBE_PROFILE_FRAGMENT = gql`
	fragment billingAccountSubscriptionProfile on BillingAccountSubscriptionProfile {
		billingaccountid
		billtype
		status
		packageid
		total_cycle
		current_cycle
		subscription_start_at
		current_cycle_start_at
		current_cycle_end_at
		packagename
	}
`;

const BILLING_ACCOUNT_RESOURCE_USAGE_PROFILE_FRAGMENT = gql`
	fragment billingAccountResourceUsageProfile on BillingAccountResourceUsageProfile {
		billingaccountid
		max_dashboard_per_project
		max_dashboard_per_billing
		max_device_per_billing
		max_flowchannel_per_billing
		max_member_per_project
		max_project_per_billing
		max_datatag_per_billing
		max_datatag_per_project
		max_datatag_per_device
		use_dashboard
		use_device
		use_flowchannel
		use_project
		use_datatag
		detail
	}
`;

const BILLING_METRIC_USAGE_PROFILE_FRAGMENT = gql`
	fragment billingMetricUsageProfile on BillingMetricUsageProfile {
		billingaccountid
		max_apicall
		max_connection
		max_datasource
		max_message
		max_shadowops
		max_store
		max_trigger
		use_apicall
		use_connection
		use_datasource
		use_message
		use_shadowops
		use_store
		use_trigger
	}
`;

const QUERY_MY_BILLING_ACCOUNT = gql`
	query {
		MyBillingAccount {
			...myBillingAccount
		}
	}
	${MY_BILLING_ACCOUNT_FRAGMENT}
`;

const QUERY_BILLING_ACCOUNT_SUBSCRIBE_PROFILE = gql`
	query ($billingaccountid: String!) {
		BillingAccountSubscriptionProfile(billingaccountid: $billingaccountid) {
			...billingAccountSubscriptionProfile
		}
	}
	${BILLING_ACCOUNT_SUBSCRIBE_PROFILE_FRAGMENT}
`;

const QUERY_BILLING_ACCOUNT_RESOURCE_USAGE_PROFILE = gql`
	query ($billingaccountid: String!) {
		BillingAccountResourceUsageProfile(billingaccountid: $billingaccountid) {
			...billingAccountResourceUsageProfile
		}
	}
	${BILLING_ACCOUNT_RESOURCE_USAGE_PROFILE_FRAGMENT}
`;
const QUERY_NETPIE_BILLING_ACCOUNT_RESOURCE_USAGE_PROFILE = gql`
	query ($billingaccountid: String!) {
		BillingAccountResourceUsageProfile(billingaccountid: $billingaccountid) {
			...billingAccountResourceUsageProfile
			use_freeboard
		}
	}
	${BILLING_ACCOUNT_RESOURCE_USAGE_PROFILE_FRAGMENT}
`;

const QUERY_BILLING_METRIC_USAGE_PROFILE = gql`
	query ($billingaccountid: String!) {
		BillingMetricUsageProfile(billingaccountid: $billingaccountid) {
			...billingMetricUsageProfile
		}
	}
	${BILLING_METRIC_USAGE_PROFILE_FRAGMENT}
`;

const QUERY_USAGE_PAGE_2 = gql`
	query ($billingaccountid: String!) {
		BillingAccountResourceUsageProfile(billingaccountid: $billingaccountid) {
			...billingAccountResourceUsageProfile
		}
		BillingMetricUsageProfile(billingaccountid: $billingaccountid) {
			...billingMetricUsageProfile
		}
		BillingAccountSubscriptionProfile(billingaccountid: $billingaccountid) {
			...billingAccountSubscriptionProfile
		}
		projectList {
			...project
		}
	}
	${BILLING_ACCOUNT_RESOURCE_USAGE_PROFILE_FRAGMENT}
	${BILLING_METRIC_USAGE_PROFILE_FRAGMENT}
	${BILLING_ACCOUNT_SUBSCRIBE_PROFILE_FRAGMENT}
	${PROJECT_FRAGMENT}
`;

const QUERY_PROJECT_BILLING_USAGE = gql`
	query ($projectid: String!) {
		ProjectBillingMetricUsageProfile(projectid: $projectid) {
			...billingMetricUsageProfile
		}
		ProjectBillingAccountSubscriptionProfile(projectid: $projectid) {
			...billingAccountSubscriptionProfile
		}
	}
	${BILLING_METRIC_USAGE_PROFILE_FRAGMENT}
	${BILLING_ACCOUNT_SUBSCRIBE_PROFILE_FRAGMENT}
`;

// billing package subscription
const BILLING_SUBSCRIPTION_FRAGMENT = gql`
	fragment billingSubscription on BillingPackageSubscription {
		billingaccountid
		subscriptionname
		billtype
		status
		packageid
		total_cycle
		current_cycle
		subscription_start_at
		current_cycle_start_at
		current_cycle_end_at
		note
	}
`;
const QUERY_NON_STARTED_BILLING = gql`
	query ($billingid: String) {
		getNonStartedBillingSubscription(billingaccountid: $billingid) {
			...billingSubscription
		}
	}
	${BILLING_SUBSCRIPTION_FRAGMENT}
`;
const START_BILLING_SUBSCRIPTION = gql`
	mutation ($billingid: String) {
		startBillingSubscription(billingaccountid: $billingid) {
			...billingSubscription
		}
	}
	${BILLING_SUBSCRIPTION_FRAGMENT}
`;

export {
	QUERY_USAGE_PAGE,
	QUERY_USAGE_PAGE_2,
	QUERY_BILLING_ACCOUNT,
	QUERY_MY_BILLING_ACCOUNT,
	QUERY_BILLING_ACCOUNT_SUBSCRIBE_PROFILE,
	QUERY_BILLING_ACCOUNT_RESOURCE_USAGE_PROFILE,
	QUERY_NETPIE_BILLING_ACCOUNT_RESOURCE_USAGE_PROFILE,
	QUERY_BILLING_METRIC_USAGE_PROFILE,
	QUERY_PROJECT_BILLING_USAGE,
	QUERY_NON_STARTED_BILLING,
	START_BILLING_SUBSCRIPTION,
};
