import {
	LOAD_FLOWAGENTS,
	LOAD_FLOWAGENT,
	CREATE_FLOWAGENT,
	UPDATE_FLOWAGENT,
	DELETE_FLOWAGENT,
} from '../actions/flowagentActions';

const initialState = {
	flowagents: [],
	flowagent: null,
	loading: false,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case LOAD_FLOWAGENTS.REQUEST:
			return {
				...state,
				loading: true,
			};
		case LOAD_FLOWAGENTS.SUCCESS:
			return {
				...state,
				flowagents: action.payload.flowagents,
				message: action.payload.message,
				loading: false,
			};
		case LOAD_FLOWAGENTS.FAILURE:
			return {
				...state,
				message: action.payload.error.data.message,
				loading: false,
			};
		case LOAD_FLOWAGENT.REQUEST:
			return {
				...state,
				loading: true,
			};
		case LOAD_FLOWAGENT.SUCCESS:
			return {
				...state,
				flowagent: action.payload.flowagent,
				message: action.payload.message,
				loading: false,
			};
		case LOAD_FLOWAGENT.FAILURE:
			return {
				...state,
				message: action.payload.error.data.message,
				loading: false,
			};
		case CREATE_FLOWAGENT.REQUEST:
			return {
				...state,
				loading: true,
			};
		case CREATE_FLOWAGENT.SUCCESS:
			return {
				...state,
				flowagent: action.payload.flowagent,
				message: action.payload.message,
				loading: false,
			};
		case CREATE_FLOWAGENT.FAILURE:
			return {
				...state,
				message: action.payload.error.data.message,
				loading: false,
			};
		case UPDATE_FLOWAGENT.REQUEST:
			return {
				...state,
				loading: true,
			};
		case UPDATE_FLOWAGENT.SUCCESS:
			return {
				...state,
				message: action.payload.message,
				flowagent: action.payload.flowagent,
				loading: false,
			};
		case UPDATE_FLOWAGENT.FAILURE:
			return {
				...state,
				message: action.payload.error.data.message,
				loading: false,
			};
		case DELETE_FLOWAGENT.REQUEST:
			return {
				...state,
				loading: true,
			};
		case DELETE_FLOWAGENT.SUCCESS:
			const newFlowagents = state.flowagents.filter(
				(flowagent) => flowagent.flowagentid !== action.payload.flowagent.flowagentid
			);
			return {
				...state,
				flowagents: newFlowagents,
				flowagent: action.payload.flowagent,
				message: action.payload.message,
				loading: false,
			};
		case DELETE_FLOWAGENT.FAILURE:
			return {
				...state,
				message: action.payload.error.data.message,
				loading: false,
			};

		default:
			return state;
	}
};
