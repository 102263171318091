import {
	CREATE_TRIGGER,
	UPDATE_TRIGGER,
	DELETE_TRIGGER,
	LOAD_TRIGGER,
	LOAD_TRIGGERS,
	TOGGLE_ALL_TRIGGERS,
	TOGGLE_TRIGGER,
	CLEAR_TRIGGER,
} from 'reduxStore/actions/triggerActions';
import { shapeMessage } from 'util/openNotification';

const initialState = {
	loading: false,
	toggling: false,
	crudLoading: false,
	message: null,

	triggers: [],
	trigger: null,

	clearingData: false,
	clearingMsg: null,
};

export default (state = initialState, action) => {
	switch (action.type) {
		// ===== LOAD TRIGGERS =====
		case LOAD_TRIGGERS.REQUEST:
		case LOAD_TRIGGER.REQUEST:
			return {
				...state,
				loading: true,
			};
		case LOAD_TRIGGERS.SUCCESS:
			return {
				...state,
				loading: false,
				triggers: action.payload.triggers,
			};
		case LOAD_TRIGGER.SUCCESS:
			return {
				...state,
				loading: false,
				trigger: action.payload.trigger,
			};
		case LOAD_TRIGGERS.FAILURE:
		case LOAD_TRIGGER.FAILURE:
			return {
				...state,
				loading: false,
				message: shapeMessage(action.type, action.payload.error),
			};

		// ===== TOGGLE TRIGGER =====
		case TOGGLE_ALL_TRIGGERS.REQUEST:
		case TOGGLE_TRIGGER.REQUEST:
			return {
				...state,
				toggling: true,
			};
		case TOGGLE_ALL_TRIGGERS.SUCCESS:
		case TOGGLE_TRIGGER.SUCCESS:
			return {
				...state,
				toggling: false,
				triggers: action.payload.triggers,
			};
		case TOGGLE_ALL_TRIGGERS.FAILURE:
		case TOGGLE_TRIGGER.FAILURE:
			return {
				...state,
				toggling: false,
				message: shapeMessage(action.type, action.payload.error),
			};

		// ===== CREATE, UPDATE, DELETE TRIGGER =====
		case DELETE_TRIGGER.REQUEST:
		case CREATE_TRIGGER.REQUEST:
		case UPDATE_TRIGGER.REQUEST:
			return {
				...state,
				crudLoading: true,
			};
		case DELETE_TRIGGER.SUCCESS:
		case CREATE_TRIGGER.SUCCESS:
		case UPDATE_TRIGGER.SUCCESS:
			return {
				...state,
				crudLoading: false,
				triggers: action.payload.triggers,
				message: shapeMessage(action.type),
			};
		case DELETE_TRIGGER.FAILURE:
		case CREATE_TRIGGER.FAILURE:
		case UPDATE_TRIGGER.FAILURE:
			return {
				...state,
				crudLoading: false,
				message: shapeMessage(action.type, action.payload.error),
			};

		// ===== CLEAR ALL TRIGGER =====
		case CLEAR_TRIGGER.REQUEST:
			return {
				...state,
				clearingMsg: null,
				clearingData: true,
			};
		case CLEAR_TRIGGER.SUCCESS:
			return {
				...state,
				clearingMsg: shapeMessage(action.type),
				clearingData: false,
				triggers: action.payload.triggers,
			};
		case CLEAR_TRIGGER.FAILURE:
			return {
				...state,
				clearingMsg: shapeMessage(action.type, action.payload.error),
				clearingData: false,
			};

		// ===== CLEAR MESSAGE =====
		case 'CLEAR_MESSAGE':
			return {
				...state,
				message: null,
				clearingMsg: null,
			};

		default:
			return state;
	}
};
