import { shapeMessage } from 'util/openNotification';
import {
	LOAD_PROJECT_PANEL,
	ENABLE_PANEL,
	DISABLE_PANEL,
	RESYNC_PANEL_MEMBER,
	CREATE_PANEL_DASHBOARD,
	UPDATE_PANEL_DASHBOARD,
	DELETE_PANEL_DASHBOARD,
} from '../actions/panelActions';

const initialState = {
	panel: null,
	loading: false,
	message: null,

	crudLoading: false,
	crudMessage: null,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case 'CLEAR_MESSAGE':
			return {
				...state,
				message: null,
				crudMessage: null,
			};
		case LOAD_PROJECT_PANEL.REQUEST:
			return {
				...state,
				loading: true,
			};
		case LOAD_PROJECT_PANEL.SUCCESS:
			return {
				...state,
				panel: action.payload.panel,
				loading: false,
			};
		case LOAD_PROJECT_PANEL.FAILURE:
			return {
				...state,
				loading: false,
				message: shapeMessage(action.type, action.payload.error),
			};
		/*------------------------------------------------------------*/
		case ENABLE_PANEL.REQUEST:
			return {
				...state,
				loading: true,
			};
		case ENABLE_PANEL.SUCCESS:
			return {
				...state,
				panel: action.payload.panel,
				message: shapeMessage(action.type),
				loading: false,
			};
		case ENABLE_PANEL.FAILURE:
			return {
				...state,
				loading: false,
				message: shapeMessage(action.type, action.payload.error),
			};
		/*------------------------------------------------------------*/
		case DISABLE_PANEL.REQUEST:
			return {
				...state,
				loading: true,
			};
		case DISABLE_PANEL.SUCCESS:
			return {
				...state,
				panel: action.payload.panel,
				message: shapeMessage(action.type),
				loading: false,
			};
		case DISABLE_PANEL.FAILURE:
			return {
				...state,
				loading: false,
				message: shapeMessage(action.type, action.payload.error),
			};
		/*------------------------------------------------------------*/
		case RESYNC_PANEL_MEMBER.REQUEST:
			return {
				...state,
				loading: true,
			};
		case RESYNC_PANEL_MEMBER.SUCCESS:
			return {
				...state,
				message: shapeMessage(action.type),
				loading: false,
			};
		case RESYNC_PANEL_MEMBER.FAILURE:
			return {
				...state,
				loading: false,
				message: shapeMessage(action.type, action.payload.error),
			};
		/*------------------------------------------------------------*/
		case CREATE_PANEL_DASHBOARD.REQUEST:
			return {
				...state,
				crudLoading: true,
			};
		case CREATE_PANEL_DASHBOARD.SUCCESS:
			return {
				...state,
				panel: action.payload.panel,
				crudLoading: false,
				crudMessage: shapeMessage(action.type),
			};
		case CREATE_PANEL_DASHBOARD.FAILURE:
			return {
				...state,
				crudLoading: false,
				crudMessage: shapeMessage(action.type, action.payload.error),
			};
		/*------------------------------------------------------------*/
		case UPDATE_PANEL_DASHBOARD.REQUEST:
			return {
				...state,
				crudLoading: true,
			};
		case UPDATE_PANEL_DASHBOARD.SUCCESS:
			return {
				...state,
				panel: action.payload.panel,
				crudLoading: false,
				crudMessage: shapeMessage(action.type),
			};
		case UPDATE_PANEL_DASHBOARD.FAILURE:
			return {
				...state,
				crudLoading: false,
				crudMessage: shapeMessage(action.type, action.payload.error),
			};
		/*------------------------------------------------------------*/
		case DELETE_PANEL_DASHBOARD.REQUEST:
			return {
				...state,
				crudLoading: true,
			};
		case DELETE_PANEL_DASHBOARD.SUCCESS:
			return {
				...state,
				panel: action.payload.panel,
				crudLoading: false,
				crudMessage: shapeMessage(action.type),
			};
		case DELETE_PANEL_DASHBOARD.FAILURE:
			return {
				...state,
				crudLoading: false,
				crudMessage: shapeMessage(action.type, action.payload.error),
			};
		/*------------------------------------------------------------*/
		default:
			return state;
	}
};
